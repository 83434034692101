import { combineReducers } from '@reduxjs/toolkit'

import { RolesUsuariosReducer } from '../rolesUsuario/store/RolesUsuariosReducers'
import { UsuarioReducer } from '../cuenta/usuario/store/UsuarioReducers'
import { DesarrolloReducer } from '../desarrollador/store/DesarrolloReducers'
import { ModuloAdminState } from './types'
import { CuentaReducer } from '../cuenta/store/CuentaReducers'
import { CertificadosReducer } from '../backOffice/certificadosDigitales/store/certificadosReducer'
import { tiporecurrenciasReducer } from '../backOffice/tipoRecurrencias/store/tiporecurrenciasReducer'
import { NotificationReducer } from '../backOffice/notificaciones/store/notificacionesReducers'
import { ConfiguracionesReducer } from '../configuracion/store/configuracionesReducers'
import { CatalogosReducers } from '../catalogos/store/catalogosReducers'
import { PlanesReducer } from '../planes/store/planes.combine.reducers'
import { PlanesMenuReducer } from '../planesMenu/store/planesMenu.combine.reducers'

export const moduloAdministracionReducer = combineReducers<ModuloAdminState>({
  rolesUsuarios: RolesUsuariosReducer,
  usuario: UsuarioReducer,
  desarrollo: DesarrolloReducer,
  cuenta: CuentaReducer,
  certificados: CertificadosReducer,
  tipoRecurrencias: tiporecurrenciasReducer,
  notificaciones: NotificationReducer,
  configuraciones: ConfiguracionesReducer,
  catalagos: CatalogosReducers,
  planes: PlanesReducer,
  planesMenu: PlanesMenuReducer,
})
