import React, { useRef } from 'react'
import { Proveedor } from '../../store/types'
import { ProveedoresService } from '../../services/proveedores.service'
import { LoadPanel } from 'devextreme-react/load-panel'
import CustomModalDevx from '../../../../views/componentes/modal/Modal'
import { PopupContent } from '../../../../views/componentes/popupContent'
import {
  CAlert,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CTooltip,
} from '@coreui/react-pro'
import RowContainer from '../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../views/componentes/colContainer'
import Labeled from '../../../../views/componentes/labeledInput/labeledInput'
import { lh, MessagesKeys } from '../../../../helpers/localizationHelper'
import {
  Validator,
  RequiredRule,
  PatternRule,
  AsyncRule,
  StringLengthRule,
} from 'devextreme-react/validator'
import { ValidationGroup } from 'devextreme-react/validation-group'
import ValidationSummary from 'devextreme-react/validation-summary'
import SelectBox from '../../../../views/componentes/selectBox/selectBox'
import {
  TipoClasificacion,
  TipoIdentificacion,
  TipoProveedor,
  TiposEstados,
  TiposOperadoras,
} from '../../../ventas/types/types'
import { GeneralService } from '../../../../services/general.service'
import { Ciudad } from '../../../ventas/store/types'
import {
  Pais,
  Provincia,
  TipoNegocio,
  ToastTypes,
} from '../../../../store/types'
import { addToast } from '../../../../store/toasterReducer'
import { localidadesService } from '../../../../services/localidades.service'
import { isMobile } from 'react-device-detect'
import TextArea from 'devextreme-react/text-area'
import CheckBox from 'devextreme-react/check-box'
import NumberBox from 'devextreme-react/number-box'
import TabContactos, { ETipoAsociado } from './tabContactos/tabContactos'
import { isMobileOnly } from 'react-device-detect'
import { useModalConvertSize } from '../../../../hooks/useModalSize'
import { findOnObject } from '../../../../helpers/Helper'
import { RootState } from '../../../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box'
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Button,
} from 'devextreme-react/data-grid'
import { ECountry } from '../../../../store/enum/countries'
import { Lookup } from 'devextreme-react'
import { getEconomicActivities } from '../../../../views/pages/componentes/wizardRegistro/helper/helper'
import { utilidades } from '../../../../helpers/utilidades'
import { regexPatterns } from '../../../../helpers/sv/patternValidations'

interface IModalAdminProveedoresProps extends React.PropsWithChildren {
  esTransportista: boolean
  codigoProveedor: number | null
  onChanged: (codigoProveedor: string) => void
  onCancel: () => void
  show: boolean
}

type ErrorInfo = {
  tieneError: boolean
  mensaje?: string
}

type TipoVisitas = {
  codigo: number
  valor: string
}

const diasVisita: Array<TipoVisitas> = [
  { codigo: 0, valor: '' },
  { codigo: 1, valor: 'LUNES' },
  { codigo: 2, valor: 'MARTES' },
  { codigo: 3, valor: 'MIERCOLES' },
  { codigo: 4, valor: 'JUEVES' },
  { codigo: 5, valor: 'VIERNES' },
  { codigo: 6, valor: 'SABADO' },
  { codigo: 7, valor: 'DOMINGO' },
]

type DatosModificarProveedor = {
  razonSocial: string
  nombreComercial: string | null
  tipoIdentificacion: TipoIdentificacion | null
  ruc: string | null
  direccion: string | null
  proveedorSeguros: boolean
  artesanoCalificacion: boolean
  transportista: boolean
  email: string | null
  clasificacion: TipoClasificacion | null
  tipoProveedor: TipoProveedor | null
  tipoEstado: TiposEstados | null
  reposicion: number
  diaVisita: TipoVisitas | null
  ciudad: Ciudad | null
  relacionada: boolean
  contactos: Array<any>
  nit: string | null
  dui: string | null
  nrc: string | null
  actividad: TipoNegocio | null
}

type TPlaca = {
  codigo: number
  placa: string
}

export const emailPatern =
  /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{1,}[\\;]?)+){1,}$/

const ModalAdminProveedores: React.FC<IModalAdminProveedoresProps> = (
  props,
) => {
  const duiRegex = useRef('^([0-9]{8}[0-9]{1})$') // xxxxxxxxx
  const nrcRegex = useRef(regexPatterns.nrc)
  const nitRegex = useRef(regexPatterns.nit)

  const dispatch = useDispatch()

  const [modalSize] = useModalConvertSize('lg')

  const [element] = React.useState('1')
  const validationGroupRef = React.useRef<any>()
  const menuSession = useSelector((state: RootState) => state.global.menu)
  const empresaSession = useSelector(
    (state: RootState) => state.global.session?.empresa,
  )
  const contratoEmpresa = useSelector(
    (state: RootState) => state.pagosSuscripcion.contratoEmpresa,
  )

  const { show, codigoProveedor, onCancel, onChanged, esTransportista } = props

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [errorInfo, seterrorInfo] = React.useState<ErrorInfo>({
    tieneError: false,
  })
  const [razonSocial, setRazonSocial] = React.useState<string>('')
  const [tiposIdentificacion, setTiposIdentificacion] = React.useState<
    Array<TipoIdentificacion>
  >([])
  const [tipoIdentificacion, setTipoIdentificacion] =
    React.useState<TipoIdentificacion | null>(null)
  const [ruc, setRuc] = React.useState<string>('')
  const [esNuevo, setEsNuevo] = React.useState(false)
  const [relacionada, setRelacionada] = React.useState(false)
  const [codigo, setCodigo] = React.useState<number>(0)
  const [nombreComercial, setNombreComercial] = React.useState<string>('')
  const [tipoProveedor, setTipoProveedor] =
    React.useState<TipoProveedor | null>(null)
  const [email, setEmail] = React.useState<string>('')
  const [direccion, setDireccion] = React.useState('')
  const [provincias, setProvincias] = React.useState<Array<Provincia>>([])
  const [provincia, setProvincia] = React.useState<Provincia | null>(null)
  const [paises, setPaises] = React.useState<Array<Pais>>([])
  const [pais, setPais] = React.useState<Pais | null>(null)
  const [ciudades, setCiudades] = React.useState<Array<Ciudad>>([])
  const [ciudad, setCiudad] = React.useState<Ciudad | null>(null)
  const [tiposProveedores, setTiposProveedores] = React.useState<
    Array<TipoProveedor>
  >([])
  const [proveedorSeguros, setProveedorSeguros] = React.useState<boolean>(false)
  const [artesanoCalificacion, setArtesanoCalificacion] =
    React.useState<boolean>(false)
  const [transportista, setTransportista] = React.useState<boolean>(false)
  const [clasificaciones, setClasificaciones] = React.useState<
    Array<TipoClasificacion>
  >([])
  const [clasificacion, setClasificacion] =
    React.useState<TipoClasificacion | null>(null)
  const [reposicion, setReposicion] = React.useState<number>(1)
  const [diasVisitas] = React.useState<Array<TipoVisitas>>(diasVisita)
  const [diaVisita, setDiaVisita] = React.useState<TipoVisitas | null>(null)
  const [tiposEstados, setTiposEstados] = React.useState<Array<TiposEstados>>(
    [],
  )
  const [estado, setEstado] = React.useState<TiposEstados | null>(null)
  const [tiposOperadora, setTiposOperadoras] = React.useState<
    Array<TiposOperadoras>
  >([])
  const [contactos, setContactos] = React.useState<Array<any>>([])
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [showErrorMessages, setShowErrorMessages] = React.useState<Array<any>>(
    [],
  )
  const [loadPerson, setLoadPerson] = React.useState<{
    load: boolean
    data: any
  }>({ load: false, data: null })
  const [availableInventario, setAvailableInventario] =
    React.useState<boolean>(false)
  const [placa, setPlaca] = React.useState<string>('')
  const [placas, setPlacas] = React.useState<Array<TPlaca>>([])
  const [codPlanVigente, setCodPlanVigente] = React.useState<number>(null)
  // SV
  const [nit, setNit] = React.useState<string>('')
  const [dui, setDui] = React.useState<string>('')
  const [nrc, setNrc] = React.useState<string>('')
  const [actividades, setActividades] = React.useState<Array<TipoNegocio> | []>(
    [],
  )
  const [actividad, setActividad] = React.useState<TipoNegocio | null>(null)

  const onInitFiltro = React.useCallback(async () => {
    const activities = await getEconomicActivities(
      empresaSession?.codigoPais ?? 1,
    )

    if (activities.length) {
      setActividades(activities ?? [])
    }
  }, [empresaSession])

  const onChangeActivitie = React.useCallback((data) => {
    let activitie = null
    if (data) {
      activitie = data
    }
    setActividad(activitie)
  }, [])

  const verificarModulosActivos = React.useCallback(() => {
    const find = findOnObject(menuSession, 'id', '44')
    if (find.length > 0) {
      setAvailableInventario(true)
    } else {
      setAvailableInventario(false)
    }
  }, [menuSession])

  const getDataPerson = React.useCallback(
    async (infoPerson) => {
      if (infoPerson.nombre && razonSocial === '') {
        setRazonSocial(infoPerson.nombre)
      }
      if (infoPerson.razonComercial && nombreComercial === '') {
        setNombreComercial(infoPerson.razonComercial)
      }
      if (infoPerson.email && email === '') {
        setEmail(infoPerson.email)
      }
      if (infoPerson.direccion && direccion === '') {
        setDireccion(infoPerson.direccion)
      }
      setLoadPerson({ load: false, data: null })
    },
    [nombreComercial, razonSocial, email, direccion],
  )

  const validateId = React.useCallback(async (params) => {
    const validacionIdentificacion =
      await GeneralService.validarIndentificacion(params.value ?? '')

    if (validacionIdentificacion.isValid) {
      setLoadPerson({ load: true, data: validacionIdentificacion })

      return Promise.resolve()
    } else {
      return Promise.reject(
        validacionIdentificacion.motivo ?? 'Error al validar identificación',
      )
    }
  }, [])

  const cargarCiudades = React.useCallback(async (provincia) => {
    let find: number = -1
    if (provincia === null) {
      find = -1
    } else {
      find = provincia.codigo
    }
    const ciudades = await localidadesService.getDpCiudades(
      find,
      'Elija una opción',
    )
    setCiudades(ciudades)
    setCiudad(ciudades[0])
  }, [])

  const cargarProvincias = React.useCallback(
    async (pais) => {
      if (pais === null) {
        setProvincias(paises[0]['provincias'] ?? [])
      } else {
        setProvincias(pais['provincias'])
        setProvincia(pais['provincias'][0])
      }
    },
    [paises],
  )

  const cargarProveedor = React.useCallback(
    async (codigoProveedor) => {
      console.log('cargarProveedor')
      if (validationGroupRef.current) {
        validationGroupRef.current.instance.reset()
      }

      try {
        setLoading(true)
        setContactos([])
        const localidades = await localidadesService.getDpPaises(
          'Elija una opción',
        )
        const tipoIdentificacionesApi =
          await ProveedoresService.getTipoIdentificacion('Identificación')
        const tipos = await ProveedoresService.getTiposProveedor()
        const tipoEstados = await ProveedoresService.getEstados('Estado')
        const tipoOperadoras = await ProveedoresService.getOperadoras(
          'Elija una Operadora',
        )
        const clasificacion = await ProveedoresService.getClasificaciones(
          'Clasificación',
        )
        const busqueda =
          codigoProveedor === null
            ? null
            : await ProveedoresService.getProveedorByCode(
                Number(codigoProveedor),
              )
        let activities: TipoNegocio[] = []
        if (empresaSession?.codigoPais === ECountry.ElSalvador) {
          activities = await getEconomicActivities(
            empresaSession?.codigoPais ?? 1,
          )
          if (activities.length) {
            setActividades(activities ?? [])
          }
        }

        setTiposProveedores(tipos)
        setClasificaciones(clasificacion)
        setTiposEstados(tipoEstados)
        setTiposOperadoras(tipoOperadoras)
        setTiposIdentificacion(tipoIdentificacionesApi)
        setPaises(localidades)

        if (!busqueda) {
          let paisMarcar: Pais = localidades[2] // default Ecuador
          if (empresaSession?.codigoPais === ECountry.ElSalvador) {
            paisMarcar = localidades.find(
              (x) => Number(x.codigo) === Number(empresaSession?.codigoPais),
            )
          }

          setEsNuevo(true)
          setCodigo(0)
          setNombreComercial('')
          setTipoProveedor(tipos[0])
          setTipoIdentificacion(tipoIdentificacionesApi[0])
          setClasificacion(clasificacion[0])
          setEmail('')
          setDireccion('')
          setEstado(tipoEstados[0])
          setTipoProveedor(tipos[0])
          setPais(paisMarcar)
          setProvincias(paisMarcar?.['provincias'] ?? [])
          setProvincia(paisMarcar?.['provincias']?.[0] ?? null)
          setProveedorSeguros(false)
          setArtesanoCalificacion(false)
          setTransportista(esTransportista)
          setReposicion(1)

          const ciudades = await localidadesService.getDpCiudades(
            -1,
            'Elija una opción',
          )
          setCiudades(ciudades)
          setCiudad(ciudades[0])
        } else {
          setLoading(true)
          const detalleProveedor = await ProveedoresService.getDetalleProveedor(
            busqueda?.codigo ?? 0,
            'PROVEEDOR',
          )

          const ciudades = await localidadesService.getDpCiudades(
            busqueda.provinciaCodigo ?? -1,
            'Elija una opción',
          )
          setCiudades(ciudades)

          const contacts: any = detalleProveedor.map(function (key) {
            return {
              ...key,
              principal: Number(key.principal) === 1 ? true : false,
            }
          })
          setEsNuevo(false)
          setCodigo(busqueda.codigo ?? 0)
          setContactos(contacts)
          setNombreComercial(busqueda.nombre ?? '')
          setRazonSocial(busqueda.razonComercial ?? '')
          setRuc(busqueda.identificacion ?? '')
          setDireccion(busqueda.direccion ?? '')
          setEmail(busqueda.email ?? '')
          setProveedorSeguros(Number(busqueda.seguros) === 1 ? true : false)
          setArtesanoCalificacion(
            Number(busqueda.artesanos) === 1 ? true : false,
          )
          setTransportista(Number(busqueda.transportista) === 1 ? true : false)
          setReposicion(Number(busqueda.intervalo) ?? 1)

          setTipoIdentificacion(
            tipoIdentificacionesApi.find(
              (x) => String(x.codigo) === String(busqueda.tipoIdentificacion),
            ) ?? null,
          )
          setTipoProveedor(
            tipos.find(
              (x) => String(x.codigo) === String(busqueda.tipoProveedorCodigo),
            ) ?? null,
          )
          setClasificacion(
            clasificacion.find(
              (x) => String(x.codigo) === String(busqueda.clasificacion),
            ) ?? null,
          )
          setEstado(
            tipoEstados.find(
              (x) => String(x.codigo) === String(busqueda.estado),
            ) ?? null,
          )
          setDiaVisita(
            diasVisitas.find(
              (x) => String(x.codigo) === String(busqueda.diaReposicion),
            ) ?? null,
          )
          setPais(
            paises.find(
              (x) => Number(x.codigo) === Number(busqueda.paisCodigo),
            ) ?? null,
          )
          setProvincias(provincias ?? [])
          setProvincia(
            provincias.find(
              (x) => Number(x.codigo) === Number(busqueda.provinciaCodigo),
            ) ?? null,
          )
          setCiudad(
            ciudades.find(
              (x) => Number(x.codigo) === Number(busqueda.ubicacion),
            ) ?? null,
          )

          setLoading(false)
          if (busqueda?.placas && busqueda?.placas !== null) {
            const placasItems: Array<TPlaca> = []
            const toObject = busqueda?.placas.split(';')
            toObject.map(function (key, i) {
              placasItems.push({ codigo: i, placa: key })
            })
            setPlacas(placasItems)
          }

          if (empresaSession?.codigoPais === ECountry.ElSalvador) {
            if (activities.length > 0) {
              setActividad(
                activities.find(
                  (x) => Number(x.codigo) === Number(busqueda.actividadCodigo),
                ) ?? null,
              )
            }
            setNit(busqueda?.identificacion ?? '')
            setDui(busqueda?.dui ?? '')
            setNrc(busqueda?.nrc ?? '')
          }
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        dispatch(
          addToast({
            id: '',
            autoHide: true,
            content: 'Error al cargar el proveedor. Error:\n' + error,
            fade: true,
            title: 'Proveedor',
            type: ToastTypes.Danger,
          }),
        )
      }
    },
    [
      dispatch,
      diasVisitas,
      esTransportista,
      empresaSession,
      paises,
      provincias,
    ],
  )

  const onRowRemove = React.useCallback(
    (editRowKey) => {
      const _plSele = placas.slice(0)
      if (editRowKey.row && editRowKey.row.rowIndex > -1) {
        _plSele.splice(editRowKey.row.rowIndex, 1)
        setPlacas(_plSele)
      }
    },
    [placas],
  )

  const validate = React.useCallback(async () => {
    let resolved = false
    let status = false
    const p = new Promise(async (resolve) => {
      while (!resolved) {
        await utilidades.sleep(50)
      }
      resolve(resolved)
    })
    const validationResult = validationGroupRef.current.instance.validate()
    if (
      validationResult?.brokenRules &&
      validationResult?.brokenRules.length > 0
    ) {
      setShowErrorMessages(validationResult?.brokenRules)
      return false
    }
    if (validationResult.status === 'pending') {
      validationResult.complete.then(async (resolve) => {
        await resolve
        status = resolve.isValid
        resolved = true
      })
      await p
      return status
    }
    return true
  }, [])

  const handleSubmit = React.useCallback(
    async (values: DatosModificarProveedor, verificarNuevo: boolean) => {
      setLoading(true)

      const validationResult = await validate()
      if (!validationResult) {
        setShowErrorPopup(true)
        setLoading(false)
        setIsSubmitting(false)
        return false
      }

      let contactos: any = []
      if (values.transportista === false) {
        if (values.contactos && values.contactos.length === 0) {
          setLoading(false)
          setIsSubmitting(false)
          dispatch(
            addToast({
              title: 'Crear proveedor',
              content: `Debe ingresar al menos un numero de contacto.`,
              type: ToastTypes.Warning,
              autoHide: 5000,
            }),
          )
          return false
        } else {
          contactos = values.contactos
        }
      } else if (values.transportista === true) {
        if (placas && placas.length === 0) {
          setLoading(false)
          setIsSubmitting(false)
          dispatch(
            addToast({
              title: 'Crear proveedor',
              content: `Debe registrar al menos una placa.`,
              type: ToastTypes.Warning,
              autoHide: 5000,
            }),
          )
          return false
        }
      }

      if (verificarNuevo === true) {
        const proveedor = await ProveedoresService.getProveedor(
          values.ruc ?? '',
        )
        setIsSubmitting(false)
        setLoading(false)
        if (proveedor) {
          dispatch(
            addToast({
              title: 'Crear proveedor',
              content: `'El proveedor con identificación ${
                values.ruc ?? ''
              } ya se encuentra registrado, si desea modificar los datos debe dar clic en la opción de edición.`,
              type: ToastTypes.Warning,
              autoHide: 2500,
            }),
          )
          return
        }
      }

      let diaVisita = '1'
      if (values.diaVisita && values.diaVisita.codigo) {
        diaVisita = String(values.diaVisita.codigo)
      }

      let ubicacion = 1
      if (values.ciudad && values.ciudad?.codigo !== -1) {
        ubicacion = values.ciudad?.codigo
      }

      let itemPlaca: any = []

      placas.map(function (key) {
        itemPlaca.push(key.placa)
      })

      itemPlaca = itemPlaca.join()

      const proveedor: Proveedor = {
        codigo: verificarNuevo ? 0 : codigo,
        tipoIdentificacion: String(values.tipoIdentificacion?.codigo) ?? '01',
        identificacion: values.ruc ?? '',
        identificacionString: values.ruc ?? '',
        nombre: values.razonSocial,
        razonComercial: values.nombreComercial ?? '',
        direccion: values.direccion ?? '',
        email: values.email ?? '',
        ubicacion: ubicacion ?? 1,
        clasificacion: values.clasificacion?.codigo ?? 0,
        diasCredito: 0,
        limiteCredito: 0,
        descuento: 0,
        saldo: 0,
        comprasAnuales: 0,
        montoPago: 0,
        montoCompra: 0,
        tipoAgenteCodigo: Number(values.tipoProveedor?.codigo) ?? 0,
        seguros: values.proveedorSeguros === true ? 1 : 0,
        artesanos: values.artesanoCalificacion === true ? 1 : 0,
        tipoProveedorCodigo: String(values.tipoProveedor?.codigo) ?? '01',
        relacionado:
          values.tipoIdentificacion?.codigo === '03'
            ? values.relacionada === true
              ? 'SI'
              : 'NO'
            : 'NO',
        estado: String(values.tipoEstado?.codigo) ?? '1',
        intervalo: values.reposicion,
        diaReposicion: diaVisita,
        transportista: values.transportista === true ? '1' : '0',
        tributario: '',
        placas: itemPlaca ?? '',
        dui: values?.dui ?? '',
        nrc: values?.nrc ?? '',
        actividadCodigo: values?.actividad?.codigo ?? null,
      }
      if (values?.nit) {
        proveedor.identificacion = values?.nit ?? '' // se almacena el nit en el propio key ruc
      }
      if (values?.dui) {
        proveedor.identificacion = values?.dui ?? '' // se almacena el dui en el propio key ruc
      }
      if (proveedor.nombre === '') {
        proveedor.nombre = values.nombreComercial ?? ''
      }
      try {
        await ProveedoresService.modificarProveedor(proveedor, contactos)
        dispatch(
          addToast({
            title: 'Proveedor',
            content: `Registro Satisfactorio.`,
            type: ToastTypes.Success,
            autoHide: 5000,
          }),
        )
        setLoading(false)
        setIsSubmitting(false)
        onChanged(proveedor.identificacion ?? '')
      } catch (error) {
        setLoading(false)
        setIsSubmitting(false)
        dispatch(
          addToast({
            type: ToastTypes.Danger,
            content: error,
            autoHide: 5000,
          }),
        )
      }
    },
    [dispatch, onChanged, validate, codigo, placas],
  )

  const onSelectionChanged = React.useCallback(async (data) => {
    if (data['selectedRowsData'].length > 0) {
      const captura = data['selectedRowsData'][0]
    }
  }, [])

  const loadPlan = React.useCallback(async () => {
    if (
      contratoEmpresa !== null &&
      contratoEmpresa?.codigoPlanVigente !== null
    ) {
      setCodPlanVigente(contratoEmpresa?.codigoPlanVigente ?? null)
    }
  }, [contratoEmpresa])

  const addPlaca = React.useCallback(() => {
    if (placa === '') {
      dispatch(
        addToast({
          id: '',
          autoHide: true,
          content: 'Ingresar la placa por favor.',
          fade: true,
          title: 'Placa',
          type: ToastTypes.Info,
        }),
      )
      return false
    }
    if (placas.length > 0) {
      const filtrado = placas.findIndex((item: any) => {
        return item.placa === placa
      })
      if (filtrado > -1) {
        dispatch(
          addToast({
            id: '',
            autoHide: true,
            content: 'Ya existe una placa ingresada con esta característica',
            fade: true,
            title: 'Placa',
            type: ToastTypes.Info,
          }),
        )
        return false
      }
    }
    const almacenadas = placas.slice()

    almacenadas.push({ codigo: placas.length + 1, placa: placa })
    setPlacas(almacenadas)
    setPlaca('')
  }, [placas, placa, dispatch])

  const getMessage = (mensaje, replace) => {
    const message = mensaje.replace('@dinamic_var@', replace)
    return message
  }

  const inputIdentificacionNIT = React.useMemo(() => {
    return (
      <div key={'textNit'}>
        <TextBox
          name="nit"
          placeholder="NIT"
          value={nit}
          onValueChange={(e) => {
            setNit(utilidades.eliminarGuiones(e))
          }}
          id={element}
        >
          <Validator>
            <PatternRule
              message="El NIT no tiene el formato correcto"
              pattern={nitRegex.current}
            />
            <RequiredRule
              trim
              message={lh.getMessage(
                MessagesKeys.ClienteNumeroIdentificacionRequerido,
              )}
            />
            <StringLengthRule
              max="14"
              message="Nit: Este campo no puede tener mas de 14 caracteres"
            />
          </Validator>
        </TextBox>
      </div>
    )
  }, [element, nit]) // validateId

  const inputIdentificacionCedula = React.useMemo(() => {
    return (
      <div key={'textCedula'}>
        <TextBox
          name="ruc"
          placeholder="C.I. / Identificación"
          value={ruc}
          onValueChange={(e) => {
            setRuc(e)
          }}
          id={element}
        >
          <Validator>
            <RequiredRule
              trim
              message={lh.getMessage(
                MessagesKeys.ClienteNumeroIdentificacionRequerido,
              )}
            />
            <StringLengthRule
              max="10"
              message={lh.getMessage(
                MessagesKeys.ClienteNumeroIdentificacionIncorrecto,
                '',
              )}
            />
            <AsyncRule
              message={lh.getMessage(
                MessagesKeys.ClienteNumeroIdentificacionIncorrecto,
                '',
              )}
              reevaluate={false}
              validationCallback={validateId}
            />
          </Validator>
        </TextBox>
      </div>
    )
  }, [element, ruc, validateId])

  const inputIdentificacionDUI = React.useMemo(() => {
    return (
      <div key={'textDui'}>
        <TextBox
          name="dui"
          placeholder="DUI"
          value={dui}
          onValueChange={(e) => {
            setDui(utilidades.eliminarGuiones(e))
          }}
          id={element}
        >
          <Validator>
            <RequiredRule message="DUI: Este campo es requerido" />
            <PatternRule
              message="DUI no tiene el formato correcto (xxxxxxxxx)"
              pattern={duiRegex.current}
            />
            <StringLengthRule
              max={10}
              message={
                'DUI: Este campo no puede tener mas de ' + 10 + ' caracteres'
              }
            />
          </Validator>
        </TextBox>
      </div>
    )
  }, [element, dui])

  const inputIdentificacionRuc = React.useMemo(() => {
    return (
      <div key={'textRuc'}>
        <TextBox
          name="ruc"
          placeholder="R.U.C / Identificación"
          value={ruc}
          onValueChange={(e) => {
            setRuc(e)
          }}
          id={element}
        >
          <Validator>
            <PatternRule
              message={lh.getMessage(
                MessagesKeys.ClienteNumeroRucIncorrecto,
                '',
              )}
              pattern="^[0-9]{5,}|^[0-9]{10}$|^[0-9]{13}$"
            />
            <AsyncRule
              message={lh.getMessage(
                MessagesKeys.ClienteNumeroRucIncorrecto,
                '',
              )}
              reevaluate={false}
              validationCallback={validateId}
            />
            <StringLengthRule
              min="13"
              message={lh.getMessage(
                MessagesKeys.ClienteNumeroRucIncorrecto,
                '',
              )}
            />
          </Validator>
        </TextBox>
      </div>
    )
  }, [element, ruc, validateId])

  const inputIdentificacionPasaporte = React.useMemo(() => {
    return (
      <div key="textPasaporte">
        <TextBox
          name="ruc"
          placeholder="..."
          value={ruc}
          onValueChange={(e) => {
            setRuc(e)
          }}
          id={element}
        >
          <Validator>
            <RequiredRule
              trim
              message={lh.getMessage(
                MessagesKeys.ClienteNumeroPasaporteRequerido,
                '',
              )}
            />
            <StringLengthRule
              max="15"
              message={getMessage(
                lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionMax, ''),
                15,
              )}
              ignoreEmptyValue
            />
          </Validator>
        </TextBox>
      </div>
    )
  }, [element, ruc])

  const inputIdentificacionDocumento = React.useMemo(() => {
    return (
      <div key="textPasaporte">
        <TextBox
          name="nit"
          placeholder="..."
          value={nit}
          onValueChange={(e) => {
            setNit(e)
          }}
          id={element}
        >
          <Validator>
            <RequiredRule
              trim
              message={lh.getMessage(
                MessagesKeys.ClienteNumeroPasaporteRequerido,
                '',
              )}
            />
            <StringLengthRule
              max="45"
              message={getMessage(
                lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionMax, ''),
                45,
              )}
              ignoreEmptyValue
            />
          </Validator>
        </TextBox>
      </div>
    )
  }, [element, nit])

  const CDocumentId = React.useMemo(() => {
    if (empresaSession?.codigoPais === ECountry.ElSalvador) {
      if (tipoIdentificacion && tipoIdentificacion.codigo === '17') {
        return (
          <CustomCol xs="12" md="4">
            <Labeled label="NIT">{inputIdentificacionNIT}</Labeled>
          </CustomCol>
        )
      } else if (tipoIdentificacion && tipoIdentificacion.codigo === '18') {
        return (
          <CustomCol xs="12" md="4">
            <Labeled label="DUI">{inputIdentificacionDUI}</Labeled>
          </CustomCol>
        )
      } else if (
        tipoIdentificacion?.codigo &&
        ['19', '20', '21'].includes(tipoIdentificacion.codigo)
      ) {
        return (
          <CustomCol xs="12" md="4">
            <Labeled label="Identificación">
              {inputIdentificacionDocumento}
            </Labeled>
          </CustomCol>
        )
      }
    } else {
      if (tipoIdentificacion && tipoIdentificacion.codigo === '02') {
        return (
          <CustomCol xs="12" md="4">
            <Labeled label="Identificación">
              {inputIdentificacionCedula}
            </Labeled>
          </CustomCol>
        )
      } else if (tipoIdentificacion && tipoIdentificacion.codigo === '01') {
        return (
          <CustomCol xs="12" md="4">
            <Labeled label="RUC">{inputIdentificacionRuc}</Labeled>
          </CustomCol>
        )
      } else {
        return (
          <CustomCol xs="12" md="4">
            <Labeled label="Identificación">
              {inputIdentificacionPasaporte}
            </Labeled>
          </CustomCol>
        )
      }
    }
  }, [
    empresaSession,
    inputIdentificacionCedula,
    inputIdentificacionPasaporte,
    inputIdentificacionRuc,
    tipoIdentificacion,
    inputIdentificacionDUI,
    inputIdentificacionDocumento,
    inputIdentificacionNIT,
  ])

  const isCountryLocation = React.useCallback((value) => {
    return new Promise((resolve, reject) => {
      if (value?.value?.paisCodigo === ECountry.ElSalvador) {
        resolve(true)
      } else {
        reject()
      }
    })
  }, [])

  const selectCiudad = React.useCallback((value) => {
    return new Promise((resolve, reject) => {
      if (value?.value?.codigo > 0) {
        resolve(true)
      } else {
        reject()
      }
    })
  }, [])

  const loadPanelShow = () => {
    return (
      <div>
        <LoadPanel
          id="loadingPanelDevx"
          shadingColor="rgba(0,0,0,0.4)"
          visible={loading}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />
      </div>
    )
  }

  function Item(data) {
    return (
      <CTooltip
        key={'tool-tipoNegocioOption'}
        placement="top"
        content={data?.descripcion ?? ''}
      >
        <div className="custom-item">
          <div className="product-name">{data.descripcion} </div>
        </div>
      </CTooltip>
    )
  }

  const fieldTipoNeg = React.useCallback((data) => {
    if (data && data?.data !== null) {
      if (data?.data?.descripcion && data?.data?.descripcion !== null) {
        return (
          <CTooltip
            key={'tool-tipoNegocio'}
            placement="top"
            content={data?.data?.descripcion ?? ''}
          >
            <div className="custom-item">
              <div className="tipoNegocio-name">
                {data?.data?.descripcion ?? ''}{' '}
              </div>
            </div>
          </CTooltip>
        )
      }
    }
  }, [])

  React.useEffect(() => {
    if (loadPerson.load === true) {
      getDataPerson(loadPerson.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadPerson])

  React.useEffect(() => {
    if (show) {
      cargarProveedor(codigoProveedor)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, codigoProveedor])

  React.useEffect(() => {
    verificarModulosActivos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    loadPlan()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    onInitFiltro()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!show) {
    return null
  }

  return (
    <>
      {loading && loadPanelShow()}
      <CustomModalDevx
        size={modalSize}
        show={show}
        title="Administración de Proveedor"
        onClose={onCancel}
        toolbarItems={[
          {
            options: {
              text: 'Guardar',
              stylingMode: 'contained',
              type: 'default',
              icon: 'check',
              onClick: () =>
                handleSubmit(
                  {
                    razonSocial: razonSocial,
                    nombreComercial: nombreComercial,
                    tipoIdentificacion: tipoIdentificacion,
                    ruc: ruc,
                    direccion: direccion,
                    proveedorSeguros: proveedorSeguros,
                    artesanoCalificacion: artesanoCalificacion,
                    transportista: transportista,
                    email: email,
                    clasificacion: clasificacion,
                    tipoProveedor: tipoProveedor,
                    tipoEstado: estado,
                    reposicion: reposicion,
                    diaVisita: diaVisita,
                    ciudad: ciudad,
                    relacionada: relacionada,
                    contactos: contactos,
                    nit: nit,
                    dui: dui,
                    nrc: nrc,
                    actividad: actividad,
                  },
                  esNuevo,
                ),
            },
            widget: 'dxButton',
            toolbar: 'bottom',
            location: 'after',
          },
          {
            options: {
              text: 'Cancelar',
              stylingMode: 'contained',
              type: 'danger',
              icon: 'remove',
              onClick: onCancel,
            },
            widget: 'dxButton',
            toolbar: 'bottom',
            location: 'after',
          },
        ]}
        height={isMobile ? '80%' : '80%'}
      >
        <fieldset>
          <ValidationGroup
            id={`valGroupModificarProveedor}${codigoProveedor}`}
            ref={validationGroupRef}
          >
            {!isMobileOnly && (
              <RowContainer>
                <CustomCol>
                  <ValidationSummary />
                </CustomCol>
              </RowContainer>
            )}
            {errorInfo.tieneError && (
              <CAlert
                key="modificaProveedorErrorAlert"
                color="danger"
                visible={true}
                dismissible
                onChange={() => {
                  seterrorInfo({ tieneError: false })
                }}
              >
                {errorInfo.mensaje}
              </CAlert>
            )}

            <RowContainer>
              <CustomCol xs="12" md="4">
                <Labeled label="Tipo identificación">
                  <SelectBox
                    id="selectTiposIdentificacion"
                    options={tiposIdentificacion}
                    placeholder="Tipo de indetificación"
                    onChange={(evt) => {
                      if (evt !== undefined) {
                        setTipoIdentificacion(evt)
                        setRuc('')
                      } else {
                        setRuc('')
                        setTipoIdentificacion(null)
                      }
                    }}
                    selected={tipoIdentificacion}
                    displayExpr="tipo"
                    keyExpr="codigo"
                    elementAttr={{
                      autocomplete: 'nope',
                      autoComplete: 'nope',
                    }}
                  >
                    <Validator>
                      <RequiredRule
                        message={lh.getMessage(
                          MessagesKeys.ClienteTipoIdentificacionRequerida,
                        )}
                      />
                    </Validator>
                  </SelectBox>
                </Labeled>
              </CustomCol>

              {CDocumentId}

              {empresaSession?.codigoPais === ECountry.ElSalvador && (
                <CustomCol xs="12" md="4">
                  <Labeled label="Contribuyente(NRC):">
                    <TextBox
                      name="nrc"
                      placeholder=""
                      value={nrc}
                      onValueChange={(evt) => {
                        setNrc(utilidades.eliminarGuiones(evt) ?? '')
                      }}
                      elementAttr={{
                        autoFocus: true,
                      }}
                      maxLength={14}
                    >
                      <Validator>
                        <PatternRule
                          message="Contribuyente: El contribuyente no tiene el formato correcto"
                          pattern={nrcRegex.current}
                        />
                        <StringLengthRule
                          max={11}
                          message={
                            'Contribuyente: Este campo no puede tener mas de ' +
                            11 +
                            ' caracteres'
                          }
                        />
                      </Validator>
                    </TextBox>
                  </Labeled>
                </CustomCol>
              )}

              {empresaSession?.codigoPais === ECountry.Ecuador && (
                <CustomCol xs="12" md="4">
                  <Labeled label="Razón Social">
                    <TextBox
                      name="razonSocial"
                      placeholder="Razón Social"
                      value={razonSocial}
                      onValueChange={(evt) => {
                        setRazonSocial(evt)
                      }}
                      elementAttr={{
                        autoFocus: true,
                      }}
                    >
                      <Validator>
                        <RequiredRule
                          trim
                          message={lh.getMessage(
                            MessagesKeys.GlobalCampoRequerido,
                            'Razón Social',
                          )}
                        />
                      </Validator>
                    </TextBox>
                  </Labeled>
                </CustomCol>
              )}
            </RowContainer>
            <RowContainer>
              <CustomCol xs="12" md="4">
                <Labeled label="Nombre Comercial">
                  <TextBox
                    name="nombreComercial"
                    placeholder="Nombre Comercial"
                    value={nombreComercial ?? ''}
                    onValueChange={(evt) => {
                      setNombreComercial(evt)
                    }}
                  >
                    <Validator>
                      <RequiredRule
                        trim
                        message={lh.getMessage(
                          MessagesKeys.GlobalCampoRequerido,
                          'Nombre Comercial',
                        )}
                      />
                    </Validator>
                  </TextBox>
                </Labeled>
              </CustomCol>
              <CustomCol xs="12" md="8">
                <Labeled label="Dirección">
                  {isMobile && (
                    <TextArea
                      value={direccion}
                      onValueChange={(evt) => {
                        setDireccion(evt)
                      }}
                      height={90}
                    >
                      <Validator>
                        <RequiredRule
                          message={lh.getMessage(
                            MessagesKeys.GlobalCampoRequerido,
                            'Dirección',
                          )}
                        />
                      </Validator>
                    </TextArea>
                  )}
                  {!isMobile && (
                    <TextBox
                      value={direccion}
                      onValueChange={(evt) => {
                        setDireccion(evt)
                      }}
                    >
                      <Validator>
                        <RequiredRule
                          message={lh.getMessage(
                            MessagesKeys.GlobalCampoRequerido,
                            'Dirección',
                          )}
                        />
                      </Validator>
                    </TextBox>
                  )}
                </Labeled>
              </CustomCol>

              {empresaSession?.codigoPais === ECountry.ElSalvador && (
                <CustomCol xs="12" md="5">
                  <Labeled label="Actividad Económica:">
                    <Lookup
                      width="100%"
                      dataSource={actividades ?? []}
                      displayExpr="descripcion"
                      searchEnabled={true}
                      searchMode={'contains'}
                      searchExpr={'descripcion'}
                      searchTimeout={200}
                      minSearchLength={3}
                      showDataBeforeSearch={true}
                      placeholder="Seleccionar"
                      showClearButton={true}
                      value={actividad ?? null}
                      onValueChange={onChangeActivitie}
                      itemRender={Item}
                      fieldComponent={fieldTipoNeg}
                    />
                  </Labeled>
                </CustomCol>
              )}
            </RowContainer>

            {!transportista && (
              <>
                <RowContainer>
                  <CustomCol xs="12" md="12">
                    <CCard>
                      <CCardBody>
                        <TabContactos
                          tipoAsociado={ETipoAsociado.cliente}
                          data={contactos}
                          actionSelect={(data) => {}}
                          tiposOperadoras={tiposOperadora}
                          returnContactos={(contactos) =>
                            setContactos(contactos)
                          }
                        />
                      </CCardBody>
                    </CCard>
                  </CustomCol>
                </RowContainer>
              </>
            )}

            <RowContainer>
              <CustomCol>
                <Labeled label="Email">
                  <TextBox
                    value={email}
                    onValueChange={(evt) => {
                      setEmail(String(evt).trim())
                    }}
                  >
                    <Validator>
                      <RequiredRule
                        message={lh.getMessage(
                          MessagesKeys.GlobalCampoRequerido,
                          'Email',
                        )}
                      />
                      <PatternRule
                        pattern={emailPatern}
                        message={lh.getMessage(
                          MessagesKeys.GlobalFormatoCampoIncorrecto,
                          'Email',
                        )}
                      />
                    </Validator>
                  </TextBox>
                </Labeled>
              </CustomCol>
            </RowContainer>

            <RowContainer>
              <CustomCol xs="12" md={'4'}>
                <Labeled label="Transportista">
                  <CheckBox
                    value={transportista}
                    onValueChange={(e) => {
                      setTransportista(e)
                    }}
                  />
                </Labeled>
              </CustomCol>

              {!transportista && (
                <>
                  <CustomCol xs="12" md="4">
                    <Labeled label="Proveedor de Seguros">
                      <CheckBox
                        value={proveedorSeguros}
                        onValueChange={(e) => {
                          setProveedorSeguros(e)
                        }}
                      />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="4">
                    <Labeled label="Artesano con calificación">
                      <CheckBox
                        value={artesanoCalificacion}
                        onValueChange={(e) => {
                          setArtesanoCalificacion(e)
                        }}
                      />
                    </Labeled>
                  </CustomCol>
                </>
              )}
            </RowContainer>

            <RowContainer>
              {transportista && (
                <>
                  <CustomCol md="4">
                    <Labeled label="Placa">
                      <TextBox
                        value={placa}
                        onValueChange={(e) => {
                          setPlaca(String(e).trim().toLocaleUpperCase())
                        }}
                        name="placaTransportista"
                        placeholder="IHU-1909"
                        onEnterKey={() => {
                          addPlaca()
                        }}
                      >
                        <TextBoxButton
                          name="btnAdd"
                          location="after"
                          options={{
                            type: 'normal',
                            stylingMode: 'contained',
                            icon: 'plus',
                            onClick: () => {
                              addPlaca()
                            },
                          }}
                        />
                      </TextBox>
                    </Labeled>
                  </CustomCol>
                </>
              )}
            </RowContainer>

            {transportista && placas.length > 0 && (
              <>
                <CRow className={'d-flex align-items-start order-2 mt-2 '}>
                  <CCol xs="12" md="6">
                    <DataGrid
                      selection={{ mode: 'single' }}
                      hoverStateEnabled={true}
                      dataSource={placas}
                      showBorders={true}
                      showRowLines={true}
                      onSelectionChanged={onSelectionChanged}
                    >
                      <FilterRow visible={true} />
                      <HeaderFilter visible={true} />
                      <Column
                        dataType="string"
                        dataField="placa"
                        caption="Placa"
                        width="20%"
                      />
                      <Column type="buttons">
                        <Button
                          name="delete"
                          onClick={onRowRemove}
                          visible={true}
                        />
                      </Column>
                    </DataGrid>
                  </CCol>
                </CRow>
              </>
            )}

            {!transportista && (
              <>
                <RowContainer>
                  <CustomCol xs="12" md="4">
                    <Labeled label="País">
                      <SelectBox
                        id="selectPais"
                        options={paises}
                        placeholder="País"
                        onChange={(evt) => {
                          if (evt !== undefined) {
                            setPais(evt)
                            cargarProvincias(evt)
                          } else {
                            setPais(null)
                            cargarProvincias(null)
                          }
                        }}
                        selected={pais}
                        displayExpr="nombre"
                        keyExpr="codigo"
                        elementAttr={{
                          autocomplete: 'nope',
                          autoComplete: 'nope',
                        }}
                      ></SelectBox>
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="4">
                    <Labeled label="Provincia">
                      <SelectBox
                        id="selectProvincia"
                        options={provincias}
                        placeholder="Provincia"
                        onChange={(evt) => {
                          if (evt !== undefined) {
                            setProvincia(evt)
                            cargarCiudades(evt)
                          } else {
                            setProvincia(null)
                            cargarCiudades(null)
                          }
                        }}
                        selected={provincia}
                        displayExpr="nombre"
                        keyExpr="codigo"
                        elementAttr={{
                          autocomplete: 'nope',
                          autoComplete: 'nope',
                        }}
                      ></SelectBox>
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="4">
                    <Labeled label="Ciudad">
                      <SelectBox
                        id="selectCiudades"
                        options={ciudades}
                        placeholder="Ciudad"
                        onChange={(evt) => {
                          if (evt !== undefined) {
                            setCiudad(evt)
                          } else {
                            setCiudad(null)
                          }
                        }}
                        selected={ciudad}
                        displayExpr="nombre"
                        keyExpr="codigo"
                        elementAttr={{
                          autocomplete: 'nope',
                          autoComplete: 'nope',
                        }}
                      >
                        <Validator>
                          <RequiredRule
                            message={lh.getMessage(
                              MessagesKeys.ProveedorUbicacionRequerida,
                            )}
                          />
                          <AsyncRule
                            reevaluate
                            message={'Ciudad: Debe seleccionar una opción'}
                            validationCallback={selectCiudad}
                          />
                          {ECountry.ElSalvador ===
                            empresaSession?.codigoPais && (
                            <AsyncRule
                              message={
                                'Ciudad: La ubicación seleccionada no corresponde al país emisor de documento.'
                              }
                              validationCallback={isCountryLocation}
                              ignoreEmptyValue
                              reevaluate={true}
                            />
                          )}
                        </Validator>
                      </SelectBox>
                    </Labeled>
                  </CustomCol>
                </RowContainer>

                <RowContainer>
                  <CustomCol xs="12" md="4">
                    <Labeled label="Clasificación">
                      <SelectBox
                        id="selectClasificacion"
                        options={clasificaciones}
                        placeholder="Clasificación"
                        onChange={(evt) => {
                          if (evt !== undefined) {
                            setClasificacion(evt)
                          } else {
                            setClasificacion(null)
                          }
                        }}
                        selected={clasificacion}
                        displayExpr="descripcion"
                        keyExpr="codigo"
                        elementAttr={{
                          autocomplete: 'nope',
                          autoComplete: 'nope',
                        }}
                      >
                        <Validator>
                          <RequiredRule
                            message={lh.getMessage(
                              MessagesKeys.ProveedorClasificacionRequerida,
                            )}
                          />
                        </Validator>
                      </SelectBox>
                    </Labeled>
                  </CustomCol>

                  <CustomCol xs="12" md="4">
                    <Labeled label="Tipo de proveedor">
                      <SelectBox
                        id="selectTipoProveedor"
                        options={tiposProveedores}
                        placeholder="Tipo de proveedor"
                        onChange={(evt) => {
                          if (evt !== undefined) {
                            setTipoProveedor(evt)
                          } else {
                            setTipoProveedor(null)
                          }
                        }}
                        selected={tipoProveedor}
                        displayExpr="descripcion"
                        keyExpr="codigo"
                        elementAttr={{
                          autocomplete: 'nope',
                          autoComplete: 'nope',
                        }}
                      >
                        <Validator>
                          <RequiredRule
                            message={lh.getMessage(
                              MessagesKeys.ProveedorTipoRequerida,
                            )}
                          />
                        </Validator>
                      </SelectBox>
                    </Labeled>
                  </CustomCol>

                  <CustomCol xs="12" md="4">
                    <Labeled label="Estado">
                      <SelectBox
                        id="selectEstados"
                        options={tiposEstados}
                        placeholder="Estado"
                        onChange={(evt) => {
                          if (evt !== undefined) {
                            setEstado(evt)
                          } else {
                            setEstado(null)
                          }
                        }}
                        selected={estado}
                        displayExpr="tipo"
                        keyExpr="codigo"
                        elementAttr={{
                          autocomplete: 'nope',
                          autoComplete: 'nope',
                        }}
                      >
                        <Validator>
                          <RequiredRule
                            message={lh.getMessage(
                              MessagesKeys.EstadoRequerido,
                            )}
                          />
                        </Validator>
                      </SelectBox>
                    </Labeled>
                  </CustomCol>

                  {empresaSession &&
                    codPlanVigente !== null &&
                    availableInventario === true && (
                      <>
                        <CustomCol xs="12" md="4">
                          <Labeled label="Intervalo de reposición">
                            <NumberBox
                              name="reposicion"
                              id="reposicion"
                              value={reposicion}
                              onValueChange={(e) => {
                                setReposicion(Number(e))
                              }}
                              showClearButton={true}
                            />
                          </Labeled>
                        </CustomCol>
                        <CustomCol xs="12" md="4">
                          <Labeled label="Día de visíta">
                            <SelectBox
                              id="selectDiasVisita"
                              options={diasVisitas}
                              placeholder=""
                              onChange={(evt) => {
                                if (evt !== undefined) {
                                  setDiaVisita(evt)
                                } else {
                                  setDiaVisita(null)
                                }
                              }}
                              selected={diaVisita}
                              displayExpr="valor"
                              keyExpr="codigo"
                              elementAttr={{
                                autocomplete: 'nope',
                                autoComplete: 'nope',
                              }}
                            >
                              <Validator></Validator>
                            </SelectBox>
                          </Labeled>
                        </CustomCol>
                      </>
                    )}
                </RowContainer>
              </>
            )}

            {isMobileOnly && (
              <>
                <RowContainer className="ms-2">
                  <CustomCol>
                    <ValidationSummary />
                  </CustomCol>
                </RowContainer>
                {showErrorPopup && (
                  <PopupContent
                    show={showErrorPopup}
                    title={'Acatha'}
                    subTitle={'Por favor revise las siguientes advertencias:'}
                    onClose={() => {
                      setShowErrorPopup(false)
                      setShowErrorMessages([])
                    }}
                    size={'sm'}
                    canClose={true}
                  >
                    <CAlert color="danger">
                      <ul>
                        {showErrorMessages.map(function (item, id) {
                          return <li key={id}>{item['message']}</li>
                        })}
                      </ul>
                    </CAlert>
                  </PopupContent>
                )}
              </>
            )}
          </ValidationGroup>
        </fieldset>

        <RowContainer>
          <CustomCol xs="12" md="4"></CustomCol>
        </RowContainer>
      </CustomModalDevx>
    </>
  )
}
export default React.memo(ModalAdminProveedores)
